<template>
  <page-layout>
    <div slot="headerContent">
      <h1 class="title">角色管理</h1>
    </div>
    <div slot="extra">
      <div class="more-info">
        <a-button icon="plus" type="primary" @click="handleAdd" class="f-r">添加</a-button>
      </div>
    </div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-form-item label="名称">
            <a-input v-model="searchParams.name" @pressEnter="search" placeholder="请输入角色名称" />
          </a-form-item>
          <a-form-item label="标识">
            <a-input v-model="searchParams.role" @pressEnter="search" placeholder="请输入角色标识" />
          </a-form-item>
          <a-form-item label="数据权限类型">
            <a-select
              allowClear
              showSearch
              v-model="searchParams.dataAuthType"
              placeholder="请择数据权限类型"
              @change="search"
            >
              <a-select-option
                v-for="(item, index) in roleDataType"
                :key="index"
                :value="item.value"
                :label="item.label"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="f-r m-r-none">
            <a-button type="primary" :loading="tableLoading" @click="search" icon="search">查询</a-button>
            <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :dataSource="tableData"
        :rowKey="(record) => record.id"
        :pagination="pagination"
        :columns="columns"
        @change="tableChange"
        :loading="tableLoading"
        :scroll="{ x: 1200 }"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </span>
        <template slot="roleType" slot-scope="text, record">
          <template v-for="item in roleType">
            <a-tag v-if="item.value === record.roleType" style="cursor: text;" :color="item.color">{{
              item.label
            }}</a-tag>
          </template>
        </template>
        <template slot="dataAuthType" slot-scope="text, record">
          <template v-if="record.roleType === 'data'">
            <template v-for="item in roleDataType">
              <a-tag v-if="record.dataAuthType === item.value" style="cursor: text;" color="blue">{{
                item.label
              }}</a-tag>
            </template>
          </template>
          <span v-if="record.roleType === 'func'"> - </span>
        </template>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template slot="nameRender" slot-scope="text">
          <span v-if="searchParams.name">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchParams.name})|(?=${searchParams.name})`, 'i'))"
            >
              <mark v-if="fragment.toLowerCase() === searchParams.name.toLowerCase()" :key="i" class="highlight">{{
                fragment
              }}</mark>
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>
        <template slot="roleRender" slot-scope="text">
          <span v-if="searchParams.role">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchParams.role})|(?=${searchParams.role})`, 'i'))"
            >
              <mark v-if="fragment.toLowerCase() === searchParams.role.toLowerCase()" :key="i" class="highlight">{{
                fragment
              }}</mark>
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>
        <template slot="operation" slot-scope="text, record">
          <div class="editable-row-operations">
            <template v-if="record.roleType === 'func'">
              <a-button type="primary" icon="gold" @click="() => openPermissionModal(record)" size="small"
                >授权</a-button
              >
              <a-divider type="vertical" />
            </template>
            <template v-if="record.roleType === 'data' && record.dataAuthType === 'deptOnly'">
              <a-button type="primary" icon="database" @click="() => openPermissionDataModal(record)" size="small"
                >数据</a-button
              >
              <a-divider type="vertical" />
            </template>
            <a-button type="default" shape="circle" icon="edit" size="small" @click="handleEdit(record.id)"></a-button>
            <a-divider type="vertical" />
            <a-button
              type="danger"
              shape="circle"
              icon="delete"
              size="small"
              @click="handleDelete(record.id)"
            ></a-button>
          </div>
        </template>
      </a-table>
    </a-card>
    <a-modal
      :maskClosable="false"
      title="角色添加"
      v-model="addModal"
      okText="添加"
      cancelText="取消"
      @ok="handleAddOk"
      :confirmLoading="confirmButtonStatus"
      @cancel="closeAddModal"
    >
      <role-form
        ref="addRef"
        type="add"
        @addSuccess="addSuccess"
        @submitError="addError"
        @submitStart="handleConfirmStart"
      ></role-form>
    </a-modal>
    <a-modal
      :maskClosable="false"
      title="角色编辑"
      v-model="editModal"
      okText="编辑"
      cancelText="取消"
      @ok="handleEditOk"
      :confirmLoading="confirmButtonStatus"
      @cancel="closeEditModal"
    >
      <role-form
        ref="editRef"
        type="edit"
        @editSuccess="editSuccess"
        @submitError="editError"
        @submitStart="handleConfirmStart"
      ></role-form>
    </a-modal>
    <a-modal
      title="角色授权"
      v-model="permissionVisible"
      okText="授权"
      cancelText="取消"
      width="400px"
      class="padding-none-modal"
      :confirmLoading="confirmButtonStatus"
      @ok="handleSavePermission"
    >
      <div v-malihu-scrollbar style="height: 500px; overflow-y: auto;">
        <div class="p-l-r-12">
          <z-tree
            ref="treeRef"
            :load-url="treeLoadUrl"
            :other-params="treeParams"
            :auto-load="false"
            :show-edit="false"
            type="checkbox"
          >
          </z-tree>
        </div>
      </div>
    </a-modal>
    <a-modal
      title="数据授权"
      v-model="permissionDataVisible"
      okText="授权"
      cancelText="取消"
      width="400px"
      class="padding-none-modal"
      :confirmLoading="confirmButtonDataStatus"
      @ok="handleSaveDataPermission"
    >
      <div v-malihu-scrollbar style="height: 500px; overflow-y: auto;">
        <div class="p-l-r-12">
          <z-tree
            ref="treeDataRef"
            :load-url="treeLoadDataUrl"
            :other-params="treeDataParams"
            :auto-load="false"
            :show-edit="false"
            type="checkbox"
          >
          </z-tree>
        </div>
      </div>
    </a-modal>
  </page-layout>
</template>

<script>
import SERVICE_URLS from '../../../api/service.url'
import menuTile from '../../common/mixins/menuTitle'
import entityCRUD from '@/views/common/mixins/entityCRUD'
import PageLayout from '@/components/page/PageLayout'
import ZTree from '../../../components/ztree/index'
import RoleForm from './RoleForm'
import { roleType, roleDataType } from '../../../api/vo/settings/roleVO'

export default {
  name: 'RoleSystemSetting',
  mixins: [menuTile, entityCRUD],
  data() {
    return {
      entityBaseUrl: SERVICE_URLS.earlBase.role,
      dateSearch: null, //日期绑定数据
      searchParams: {
        q: '',
        searchKey: '',
        status: null,
        number: 0,
        size: 10,
        direction: '',
        order: 'name',
        name: '',
        role: '',
        dataAuthType: undefined,
      },
      roleType: roleType,
      roleDataType: roleDataType,
      initColumns: [
        {
          title: '#',
          key: 'serial',
          width: 70,
          scopedSlots: { customRender: 'serial' },
        },
        {
          title: '角色名称',
          dataIndex: 'name',
          key: 'name',
          width: 280,
          scopedSlots: {
            filterIcon: 'filterIcon',
            customRender: 'nameRender',
          },
        },
        {
          title: '角色标识',
          dataIndex: 'role',
          key: 'role',
          width: 180,
          scopedSlots: {
            filterIcon: 'filterIcon',
            customRender: 'roleRender',
          },
        },
        {
          title: '角色类型',
          dataIndex: 'roleType',
          key: 'roleType',
          width: 150,
          align: 'center',
          scopedSlots: { customRender: 'roleType' },
        },
        {
          title: '数据权限',
          dataIndex: 'dataAuthType',
          width: 150,
          align: 'center',
          scopedSlots: { customRender: 'dataAuthType' },
        },
        {
          title: '角色描述',
          dataIndex: 'description',
          key: 'description',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          align: 'right',
          width: 200,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      selectedKeys: null,
      //permission start
      permissionVisible: false,
      treeLoadUrl: SERVICE_URLS.resource.tree,
      permissionDataVisible: false,
      treeLoadDataUrl: SERVICE_URLS.organization.treeData,
      treeParams: {
        rId: '',
      },
      treeDataParams: {
        roleId: null,
        opType: 2,
        orgIds: [],
      },
      confirmButtonDataStatus: false,
      // permission end
    }
  },
  mounted() {
    // ant-design 默认生成的表格 overflow-x 滚动为 scroll ，为了保证在没有滚动时不显示滚动条，修改为 auto
    // eslint-disable-next-line
    $('.ant-table-wrapper .ant-table-body').css('overflow-x', 'auto')
  },
  methods: {
    /*打开菜单权限分配窗口*/
    openPermissionModal(value) {
      this.treeParams.rId = value.id
      this.permissionVisible = true
      this.$nextTick(() => {
        this.$refs.treeRef.loadTree()
      })
    },
    /*打开数据权限分配窗口*/
    openPermissionDataModal(value) {
      this.treeDataParams.roleId = value.id
      this.permissionDataVisible = true
      this.$nextTick(() => {
        this.$refs.treeDataRef.loadTree()
      })
    },
    /*保存菜单权限*/
    handleSavePermission() {
      const treeNodes = this.$refs.treeRef.getAllSelectNode()
      let ids = []
      for (let i = 0; i < treeNodes.length; i++) {
        ids.push(treeNodes[i].id)
      }
      this.$http(this, {
        url: SERVICE_URLS.role.resourceSave,
        successTipsContent: '角色授权编辑成功',
        params: {
          id: this.treeParams.rId,
        },
        data: ids,
        loading: 'confirmButtonStatus',
        success: () => {
          this.permissionVisible = false
        },
      })
    },
    /*保存数据权限*/
    handleSaveDataPermission() {
      const treeNodes = this.$refs.treeDataRef.getAllSelectNode()
      let ids = []
      for (let i = 0; i < treeNodes.length; i++) {
        ids.push(treeNodes[i].id)
      }
      this.treeDataParams.orgIds = ids
      this.$http(this, {
        url: SERVICE_URLS.role.dataUpdate,
        successTipsContent: '数据授权编辑成功',
        data: this.treeDataParams,
        loading: 'confirmButtonDataStatus',
        success: () => {
          this.permissionDataVisible = false
        },
      })
    },
  },
  components: {
    ZTree,
    RoleForm,
    PageLayout,
  },
}
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0;
}
</style>
